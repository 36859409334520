<script>
export default {
  props: {
    employer: {
      required: true,
      type: Object,
    },
    company: {
      required: true,
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      userPassword: '',
      requestDataPopup: false,
    };
  },
  computed: {
    validateRequestData() {
      return !this.errors.any() && this.userPassword !== '';
    },
  },
  methods: {
    initRequestData() {
      this.requestDataPopup = true;
    },
    resetPopup() {
      this.requestDataPopup = false;
      this.userPassword = '';
      this.errors.clear();
    },
    async requestData() {
      if (!this.validateRequestData) return;
      this.$vs.loading({ type: 'corners' });
      try {
        await this.$store.dispatch('auth/reauthenticate', this.userPassword);
        const requestedData = {
          userType: 'employer',
          employerData: this.employer,
          companyData: this.company ?? {},
        };
        const response = await fetch(process.env.VUE_APP_REQUEST_DATA_URL, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(requestedData),
        });
        this.resetPopup();
        this.$vs.loading.close();
        if (!response.ok) {
          return this.$emit('displayError', response);
        }
        this.$vs.notify({
          title: 'Your data is on its way!',
          color: 'success',
          text: `An email has been sent to ${this.employer.email}`,
          time: 8000,
        });
      } catch (error) {
        this.$vs.loading.close();
        this.userPassword = '';
        this.$emit('displayError', error.message);
      }
    },
  },
};
</script>

<template>
  <div class="vx-col sm:w-1/2 w-full lg:mb-3 mb-5 flex flex-col">
    <h5 class="mb-5">Request personal data</h5>
    <p>We will email you a breakdown of the data we have stored that is related to you and your account.</p>
    <vs-button class="w-full md:w-2/3 xxl:w-1/3 mt-4 sm:mt-auto" color="#ADCA63" @click="initRequestData">Request data</vs-button>

    <vs-popup title="We need to make sure it is you" :active.sync="requestDataPopup" @close="resetPopup()">
      <p class="mb-3">Please enter your current password to request your data.</p>

      <vs-input
        v-model.trim="userPassword"
        v-validate="'required|min:6|max:20'"
        data-vv-validate-on="blur"
        :danger="!!errors.first('password')"
        :danger-text="errors.first('password')"
        name="password"
        type="password"
        class="w-full"
        placeholder="Input your current password..."
      ></vs-input>

      <vs-button :disabled="!validateRequestData" class="mt-5" color="#ADCA63" @click="requestData()"> Request data </vs-button>
    </vs-popup>
  </div>
</template>
