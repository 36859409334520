<template>
  <div class="flex flex-col-reverse lg:flex-row flex-wrap">
    <SettingsTour />
    <div class="vx-col lg:w-3/4 w-full mb-base lg:pl-2 lg:pr-5 mr-auto">
      <vx-card class="border-solid border-2 border-gray-200">
        <h2 class="pt-2">Account Information</h2>
        <vs-divider></vs-divider>
        <div class="vx-row">
          <ChangeEmail :employer="employer" @displayError="displayError" />
          <ChangePassword :employer="employer" @displayError="displayError" />
        </div>
      </vx-card>
      <vx-card class="mt-5 border-solid border-2 border-gray-200">
        <EmailPreferences @displayError="displayError" />
      </vx-card>
      <vx-card class="mt-5 border-solid border-2 border-gray-200">
        <h2 class="pt-2">Account Management</h2>
        <vs-divider></vs-divider>
        <div class="vx-row">
          <RequestData :employer="employer" :company="company" @displayError="displayError" />
          <DeleteAccount :employer="employer" :company="company" @displayError="displayError" />
        </div>
      </vx-card>
    </div>
    <div class="vx-col w-full lg:w-1/4 mb-base mt-auto mr-auto">
      <vx-card class="p-2 border-solid border-2 border-gray-200"
        ><p class="font-medium"
          >We take your privacy and the security of your data very seriously. We will never give or sell your information to anyone else
          without your consent.
        </p>
        <br />
        <p class="font-medium"
          >View our <a href="https://www.recruitaguide.co.za/privacy-policy" target="_blank">Privacy Policy</a> for more information.
        </p>
      </vx-card>
    </div>
  </div>
</template>

<script>
import ChangePassword from '../components/settings-components/ChangePassword.vue';
import ChangeEmail from '../components/settings-components/ChangeEmail.vue';
import EmailPreferences from '../components/settings-components/EmailPreferences.vue';
import RequestData from '../components/settings-components/RequestData.vue';
import DeleteAccount from '../components/settings-components/DeleteAccount.vue';
import SettingsTour from '../components/tours/SettingsTour.vue';
export default {
  components: {
    ChangePassword,
    ChangeEmail,
    EmailPreferences,
    RequestData,
    DeleteAccount,
    SettingsTour,
  },
  data() {
    return {
      employer: this.$store.getters['employerModule/getUser'],
      company: {},
      errorTitle: '',
      errorColor: '',
      errorText: '',
      newComp: {
        descr: '',
        selected: '',
        name: '',
        type: '',
        number: '',
        website: '',
        email: '',
        accomodation: '',
        meals: '',
        flights: '',
        leave: '',
        tips: '',
        logo: 'https://firebasestorage.googleapis.com/v0/b/guidenova.appspot.com/o/company_logos%2FRAG%20Logo%20Light%20copy.png?alt=media&token=8e48f01e-2973-4f0f-be21-287228f34f1d',
        banner: '',
        employerId: '',
      },
    };
  },
  created() {
    if (this.$store.getters['companiesModule/getComp'] !== undefined) {
      this.company = Object.assign({}, this.$store.getters['companiesModule/getComp']);
    } else {
      this.company = this.newComp;
    }
  },

  methods: {
    displayError(errorMessage) {
      switch (errorMessage) {
        case 'Firebase: Error (auth/wrong-password).':
          this.errorTitle = 'Incorrect password';
          this.errorColor = 'warning';
          this.errorText = 'The password provided is incorrect for this account.';
          break;

        case 'Firebase: Error (auth/email-already-in-use).':
          this.errorTitle = 'Email in use';
          this.errorColor = 'warning';
          this.errorText = 'That email is already in use.';
          break;

        default:
          this.errorTitle = 'Something went wrong';
          this.errorColor = 'warning';
          this.errorText = 'There was an error trying to process your request.';
          break;
      }
      return this.$vs.notify({
        title: this.errorTitle,
        color: this.errorColor,
        text: this.errorText,
        time: 8000,
      });
    },
  },
};
</script>

<style scoped>
* {
  font-family: inherit;
}
.con-vs-checkbox {
  align-items: flex-start !important;
  margin-left: 0px !important;
}
.warning-text {
  color: #ea5455;
}
</style>
