<script>
import { getAuth, deleteUser } from 'firebase/auth';
import { doc, deleteDoc, setDoc } from 'firebase/firestore';
import { db } from '@/firebase/firebaseConfig';
import { getStorage, ref, deleteObject } from '@firebase/storage';

export default {
  props: {
    employer: {
      required: true,
      type: Object,
    },
    company: {
      required: true,
      type: Object,
    },
  },
  data() {
    return {
      userPassword: '',
      deleteAccountPopup: false,
      deleteReason: '',
      explanation: '',
    };
  },
  computed: {
    validateDeleteAccount() {
      if (this.deleteReason !== 'Other') {
        return !this.errors.any() && this.userPassword !== '' && this.deleteReason !== '';
      } else {
        return !this.errors.any() && this.userPassword !== '' && this.deleteReason !== '' && this.explanation !== '';
      }
    },
    filters() {
      return this.$store.getters['filtersModule/getFilters'];
    },
  },
  watch: {
    deleteReason() {
      this.explanation = '';
    },
  },
  methods: {
    resetPopup() {
      this.deleteAccountPopup = false;
      this.deleteReason = '';
      this.explanation = '';
      this.userPassword = '';
      this.errors.clear();
    },
    initDeleteAccount() {
      this.deleteAccountPopup = true;
    },
    async deleteAccount() {
      if (!this.validateDeleteAccount) return;
      this.$vs.loading({ type: 'corners' });
      const empId = this.employer.employerId;
      const auth = getAuth();
      try {
        await this.$store.dispatch('auth/reauthenticate', this.userPassword);
        this.deleteAccountPopup = false;
        await this.addDeleteReason();
        await this.deleteCompLogo();
        await this.$store.dispatch('employerModule/closeDBChannel', { clearModule: true });
        await deleteDoc(doc(db, 'employers', empId));
        await deleteUser(auth.currentUser);
        this.$vs.loading.close();
        this.$router.push({ name: 'userManagement', params: { mode: 'account-deleted' } });
      } catch (error) {
        this.$vs.loading.close();
        this.userPassword = '';
        this.$emit('displayError', error.message);
      }
      this.userPassword = '';
    },
    async addDeleteReason() {
      return await setDoc(doc(db, 'feedback', 'employer_feedback', 'account_deletions', this.employer.employerId), {
        deletedAt: new Date(),
        deleteReason: this.deleteReason,
        explanation: this.explanation,
      });
    },
    async deleteCompLogo() {
      if (this.company.logo) {
        const storage = getStorage();
        const logoRef = ref(storage, this.company.logo);
        if (logoRef.name !== 'RAG Logo Light copy.png') {
          return await deleteObject(logoRef);
        }
      }
      return;
    },
  },
};
</script>

<template>
  <div class="vx-col sm:w-1/2 w-full lg:mb-3 mb-5">
    <h5 class="mb-5">Delete my account</h5>
    <p
      >This will permanently delete your account and any data associated with. If you are experiencing any issues with your account, please
      contact us so we can help.</p
    >
    <vs-button class="mt-4 w-full md:w-2/3 xxl:w-1/3" color="danger" @click="initDeleteAccount">Delete account</vs-button>

    <vs-popup title="Are you sure?" :active.sync="deleteAccountPopup" @close="resetPopup()">
      <p class="mb-3">You are about to delete your account and permanently remove any data associated with it.</p>
      <p class="font-semibold mb-3 warning-text" color="danger">This action cannot be undone.</p>
      <div class="overflow-hidden">
        <p class="mb-5">Please provide feedback on why you are deleting your account. </p>

        <vs-select v-model="deleteReason" placeholder="Select a reason" class="w-full">
          <vs-select-item
            v-for="(item, index) in filters.delete_account_options['delete_account_options']"
            :key="index"
            :value="item"
            :text="item"
          />
        </vs-select>

        <div class="mt-5 mb-5">
          <vs-textarea
            v-if="deleteReason === 'Other'"
            v-model="explanation"
            v-validate="'required|max:250'"
            counter="250"
            data-vv-validate-on="blur"
            name="explanation"
            :color="errors.has('explanation') ? 'danger' : 'dark'"
            :class="errors.has('explanation') ? 'focusx' : ''"
            class="w-full mb-4 sm:mb-0"
            placeholder="Please provide more information"
          ></vs-textarea>
          <span v-show="errors.has('explanation')" class="text-danger text-xxs ml-1">{{ errors.first('explanation') }}</span>
        </div>
      </div>
      <p class="mb-3">Please enter your current password to delete your account.</p>

      <vs-input
        v-model.trim="userPassword"
        v-validate="'required|min:6|max:20'"
        color="dark"
        data-vv-validate-on="blur"
        :danger="!!errors.first('password')"
        :danger-text="errors.first('password')"
        name="password"
        type="password"
        class="w-full"
        placeholder="Input your current password..."
      ></vs-input>
      <vs-button :disabled="!validateDeleteAccount" class="mt-5" color="danger" @click="deleteAccount()"> Delete my account </vs-button>
      <vs-button class="mt-5 ml-0 xxsm:ml-3" color="grey" @click="resetPopup()"> Cancel </vs-button>
    </vs-popup>
  </div>
</template>

<style>
* {
  font-family: inherit;
}
</style>
